
import { defineComponent } from "vue";
import { enUS, zhCN } from "@/plugins/i18n";
import VImage from "@/components/Image.vue";

export default defineComponent({
  name: "Logo",

  components: {
    VImage
  },

  i18n: {
    messages: {
      [zhCN]: {
        company: "上海玉串自动化科技有限公司",
      },
      [enUS]: {
        company: "Yuchuan Automation Technology",
      }
    }
  }
});
