
import { defineComponent } from "vue";
import NavDropdown from "./NavDropdown.vue";

export default defineComponent({
  name: "NavItem",

  components: {
    NavDropdown
  },

  props: {
    menus: {}
  }
});
