
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavMenu",

  props: {
    menus: {}
  },

  methods: {
    handleClick () {
      this.$emit("click-item");
    }
  }
});
