
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "NavItemDropdown",

  props: {
    text: {},
    menus: {
      type: Object as PropType<any[]>,
      required: true
    }
  },

  data: () => ({
    isHovered: false
  }),

  computed: {
    isNavMatched (): any {
      return this.menus?.some(
        (menu) => this.$route.fullPath.includes(menu.route)
      );
    }
  }
});
