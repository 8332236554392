import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "dropdown-menu" }
const _hoisted_2 = {
  key: 0,
  class: "dropdown-item"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link")
  const _directive_class = _resolveDirective("class")
  const _directive_active = _resolveDirective("active")

  return _withDirectives((_openBlock(), _createBlock("div", {
    class: "nav-item nav-dropdown",
    onMouseover: _cache[2] || (_cache[2] = $event => (_ctx.isHovered = true)),
    onMouseout: _cache[3] || (_cache[3] = $event => (_ctx.isHovered = false))
  }, [
    _createVNode("a", null, _toDisplayString(_ctx.text), 1),
    _withDirectives(_createVNode("div", _hoisted_1, [
      _createVNode("div", {
        class: "dropdown-content",
        style: {"width":"200px"},
        onClick: _cache[1] || (_cache[1] = $event => (_ctx.isHovered = false))
      }, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.menus, (menu, key) => {
          return (_openBlock(), _createBlock(_Fragment, { key: key }, [
            (menu.link)
              ? (_openBlock(), _createBlock("div", _hoisted_2, [
                  _createVNode("a", {
                    href: menu.link
                  }, _toDisplayString(menu.text), 9, ["href"])
                ]))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  custom: "",
                  to: menu.route
                }, {
                  default: _withCtx(({ navigate, isActive }) => [
                    _withDirectives(_createVNode("p", {
                      class: "dropdown-item",
                      onClick: navigate
                    }, _toDisplayString(menu.text), 9, ["onClick"]), [
                      [_directive_class, isActive, "is-active"]
                    ])
                  ]),
                  _: 2
                }, 1032, ["to"]))
          ], 64 /* STABLE_FRAGMENT */))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ], 512), [
      [_directive_active, _ctx.isHovered]
    ])
  ], 544)), [
    [_directive_active, _ctx.isNavMatched]
  ])
}