import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "menu" }
const _hoisted_2 = { class: "menu-list" }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link")
  const _directive_class = _resolveDirective("class")

  return (_openBlock(), _createBlock("aside", _hoisted_1, [
    _createVNode("ul", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.menus, (menu, key) => {
        return (_openBlock(), _createBlock("li", { key: key }, [
          (menu.link)
            ? (_openBlock(), _createBlock("a", {
                key: 0,
                href: menu.link
              }, _toDisplayString(menu.text), 9, ["href"]))
            : (!menu.children)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  custom: "",
                  to: menu.route,
                  key: key + 'item'
                }, {
                  default: _withCtx(({ navigate }) => [
                    _createVNode("a", {
                      onClick: () => navigate() && _ctx.handleClick()
                    }, _toDisplayString(menu.text), 9, ["onClick"])
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : (_openBlock(), _createBlock("a", _hoisted_3, _toDisplayString(menu.text), 1)),
          (menu.children)
            ? (_openBlock(), _createBlock("ul", _hoisted_4, [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(menu.children, (submenu, key) => {
                  return (_openBlock(), _createBlock("li", { key: key }, [
                    (submenu.link)
                      ? (_openBlock(), _createBlock("a", {
                          key: 0,
                          href: submenu.link
                        }, _toDisplayString(submenu.text), 9, ["href"]))
                      : (_openBlock(), _createBlock(_component_router_link, {
                          key: 1,
                          custom: "",
                          to: submenu.route,
                          onClick: _withModifiers(_ctx.handleClick, ["self"])
                        }, {
                          default: _withCtx(({ navigate, isActive }) => [
                            _withDirectives(_createVNode("a", {
                              onClick: () => navigate() && _ctx.handleClick()
                            }, _toDisplayString(submenu.text), 9, ["onClick"]), [
                              [_directive_class, isActive, "is-active"]
                            ])
                          ]),
                          _: 2
                        }, 1032, ["to", "onClick"]))
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}