import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "is-flex is-hidden-touch" }
const _hoisted_2 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_3 = { class: "nav-item" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_NavDropdown = _resolveComponent("NavDropdown")
  const _directive_class = _resolveDirective("class")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.menus, (menu, key) => {
      return (_openBlock(), _createBlock(_Fragment, {
        key: menu.text
      }, [
        (menu.link)
          ? (_openBlock(), _createBlock("div", _hoisted_2, [
              _createVNode("a", {
                target: "_black",
                href: menu.link
              }, _toDisplayString(menu.text), 9, ["href"])
            ]))
          : (!menu.children)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                custom: "",
                to: menu.route
              }, {
                default: _withCtx(({ isActive, navigate }) => [
                  _withDirectives(_createVNode("div", _hoisted_3, [
                    _createVNode("a", { onClick: navigate }, _toDisplayString(menu.text), 9, ["onClick"])
                  ], 512), [
                    [_directive_class, isActive, "is-active"]
                  ])
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_openBlock(), _createBlock(_component_NavDropdown, {
                key: key + 'children',
                text: menu.text,
                menus: menu.children
              }, null, 8, ["text", "menus"]))
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}