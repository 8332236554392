import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"white-space":"nowrap"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_VImage = _resolveComponent("VImage")
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock(_component_router_link, {
    to: "/",
    custom: ""
  }, {
    default: _withCtx(({ navigate }) => [
      _createVNode("div", {
        class: "nav-logo",
        onClick: navigate
      }, [
        _createVNode(_component_VImage, {
          path: "common/logo.png",
          class: "app-logo-image",
          style: {"width":"96px","height":"44px","margin-bottom":"2px"}
        }),
        _createVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("company")), 1)
      ], 8, ["onClick"])
    ]),
    _: 1
  }))
}