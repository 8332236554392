import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_ctx.image.indexOf('@') === -1)
    ? (_openBlock(), _createBlock("img", {
        key: 0,
        src: _ctx.image
      }, null, 8, ["src"]))
    : (_ctx.image.indexOf('t2.png') !== -1)
      ? (_openBlock(), _createBlock("img", {
          key: 1,
          class: "bb",
          src: require('@/assets/Z.gif')
        }, null, 8, ["src"]))
      : _createCommentVNode("", true)
}